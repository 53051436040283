// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

var $ = require('jquery');
var Link = require('../_modules/link/link');

$(function() {
  new Link(); // Activate Link modules logic
  console.log('Welcome to Yeogurt!');
});

$('.nav-toggle').click(function() {
	$(this).toggleClass('nav-open');
	$('.main-navigation').toggleClass('open');
	return false;
});